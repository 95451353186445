@import "~material-icons/iconfont/material-icons.scss";
@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');

// NEW CSS

.student-layout {
	display: flex;
	overflow: hidden;
	background: #f8f8f8;
	&_wrap {
		flex-grow: 1;
		overflow: auto;
		height: 100vh;
	}

	&_content {
		padding: 25px 40px;
	}

	&_wrapper {
		padding: 15px;
		@media screen and (min-width: 1280px) {
			padding: 25px 40px;
		}

		.main-mid,
		.main-structure,
		.wall-grid .content {
			padding: 0;
			width: 100%;
		}

		.wall-grid {
			.filter {
				display: none;
			}
		}
	}
}

.post-card {
  &--content {
    a {
      color: #239f87;
      text-decoration: underline;
    }

    ul, ol {
      li {
        font-size: 18px;
        line-height: 28px;
        color: #6f6f6f;
        margin: 0;
        padding: 0 0 16px;
      }
    }
  }
}

.student-sidebar {
	background: linear-gradient(178.3deg, #332c42 3.54%, #0e081b 64.37%);
	height: 100vh;
	width: 248px;
	flex-shrink: 0;
	padding: 25px 18px;
	position: relative;
	// display: none;

	.wrap {
		height: 100%;
	}

	@media screen and (min-width: 768px) {
		display: block;
	}

	.graphic {
		position: absolute;
		left: 0;
		bottom: 0;
	}

	.logo {
		display: block;
		margin: 0 auto;
	}

	.button-dropdown {
		margin: 24px 0;
		.dropdown-toggle {
			min-width: 100%;
			background-color: #474253 !important;
			border: 1px solid #241f2f !important;
			font-size: 14px !important;
			font-weight: 400;
			box-shadow: none;
			padding: 0 10px 0 23px;

			.icon {
				height: 20px;
				width: 20px;
				display: flex;
				background: #241f2f;
				border-radius: 50%;
				align-items: center;
				justify-content: center;

				svg {
					color: #ffffff;
					margin-left: 0;
					margin-bottom: -2px;
				}
			}
		}

		.dropdown-menu {
			background-color: #474253 !important;
			border: 1px solid #241f2f !important;
			border-radius: 8px;
			top: 39px !important;
			transform: none !important;

			.custom-item-text {
				font-size: 14px;
				line-height: normal;
				font-weight: 400;
			}
		}
	}

	ul {
		list-style: none;
		margin: 0 -18px;
		padding: 0 40px;
		overflow: auto;
		max-height: calc(100% - (50px + 42px + 40px + 48px + 50px));

		ul {
			padding-left: 50px;
			padding-right: 15px;
			margin-top: 8px;
			li {
				a {
					img {
						display: none;
					}
				}
			}
		}

		li {
			& + li {
				margin-top: 8px;
			}

			&.active {
				a {
					color: #00b8be;
				}
			}

			a {
				display: flex;
				align-items: center;
				font-weight: 400;
				font-size: 14px;
				line-height: 28px;
				color: #898989;

				img {
					margin-right: 10px;
				}
			}
		}
	}

	@media screen and (max-width: 1023px) {
		position: fixed;
		left: -100%;
		top: 71px;
		height: calc(100vh - 71px);
		width: 100%;
		z-index: 9999;
		padding: 0;
		background: rgba(0, 0, 0, 0.5);
		transition: all ease-in-out 0.3s;

		.wrap {
			width: 248px;
			padding: 15px;
			height: 100%;
			background: linear-gradient(178.3deg, #332c42 3.54%, #0e081b 64.37%);
		}

		ul {
			padding: 0 15px;
			margin: 0 -15px;
			max-height: 70%;
		}

		.button-dropdown {
			display: none;
		}

		.logo {
			display: none;
		}

		&.show {
			left: 0;
		}
	}
}

.student-nav {
	height: 71px;
	background: #ffffff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 15px;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 99;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06);

	@media screen and (min-width: 1280px) {
		padding: 0 40px;
		box-shadow: none;
	}

	.logo {
		align-items: center;
		display: flex;

		svg {
			margin-right: 10px;
			font-size: 24px;
		}
	}

	&_wrap {
		display: flex;
		align-items: center;
	}

	.nav-link {
		padding: 0;
		display: flex;
		align-items: center;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 0.05em;
		text-transform: capitalize;
		color: #242626;
		transition: none;

		&.active {
			color: #00a689;
		}

		img {
			margin-right: 8px;
		}

		& + .nav-link {
			margin-left: 30px;
		}
	}

	.user-dropdown .dropdown-toggle .user-image {
		margin-right: 5px;
	}

	@media screen and (max-width: 1023px) {
		.nav {
			display: none;
		}
	}

	@media screen and (max-width: 767px) {
		.user-dropdown .dropdown-toggle {
			min-width: 1px;
			.user-name {
				display: none;
			}

			.user-image {
				margin-right: 0;
			}

			.icon-down-arrow {
				display: none;
			}
		}
	}

	@media screen and (min-width: 1024px) {
		.logo {
			display: none;
		}
	}
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
	.student-nav .nav-link + .nav-link {
		margin-left: 20px;
	}
}

@media screen and (max-width: 1023px) {
	.student-layout {
		&_wrap {
			padding-bottom: 100px;
		}
		.button-dropdown.dropdown {
			background: #f8f8f8;
		}
	}
	.course-dropdown-xs {
		position: sticky;
		top: 71px;
		z-index: 9;
		left: 0;

		.mobile-menu-tabs a {
			font-size: 12px;
			height: 100%;
			width: calc(100% / 5);

			img {
				margin-bottom: 5px;
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
	body .priamry-test-card {
		padding: 15px;
		.test-card-footer {
			margin: 0 -15px;
		}
	}
}

body {

  .custom-thumbnail.custom-thumbnail-secondary {
    &>span {
      img {
        object-fit: cover;
      }
    }
    @media screen and (min-width:768px) {
      .custom-thumbnail-secondary_body ul + p {
        min-height: 59px !important;
        align-items: flex-start;
      }
    }

  }

  .test-page.secondary .test-page--body {
    @media screen and (max-width: 767px) {
      min-height: calc(100vh - (98px + 67px));
      height: calc(100vh - (98px + 67px));

      .right-col .q-pallete {
        max-width: 350px;
      }
    }
  }
	.user-structure {
    display: block;
    @media screen and (min-width: 1280px) {
      display: flex;
    }
    &--info {
      @media screen and (max-width: 1279px) {
        width: 100%;
        height: 50vh;
      }
      &.scholarship {
        @media screen and (max-width: 767px) {
          height: 40vh;
        }
        & + .user-structure--form {
          @media screen and (max-width: 767px) {
            height: 60vh;
          }
        }
        .info-body {
          height: 80px;
        }
        .info-header {
          // padding: 50px 30px;
          // height: 40vh;
          height: 100%;
          @media screen and (max-width: 767px) {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          @media screen and (min-width: 768px) {
            height: calc(100vh - 80px);
            padding: 10px 20px;
          }
          img {
            max-height: inherit;
            max-width: 100%;
            width: auto !important;
            height: auto;
          }
        }
      }
    }

    &--form {
      @media screen and (max-width: 1279px) {
        width: 100%;
        min-height: 50vh;
      }
    }
		.info-header {
      background-color: #fff;
      padding: 50px 20px;
      height: 50vh;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin: 0 auto;
        display: block;
      }
			@media screen and (min-width: 1280px) {
				padding-bottom: 0;
        padding-top: 28px;
			}
			@media screen and (min-width: 1280px) {
				height: 200px;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: auto !important;
					height: auto;
          max-height: 100%;
          max-width: 100%;
					object-fit: contain;
				}
			}
		}
		.info-body {
      display: none;
			@media screen and (min-width: 1280px) {
				height: calc(100% - (200px + 65px));
        display: flex;

			}

      img {
        max-height: 230px;
      }

      h1 {
        margin-bottom: 2rem;
      }
		}

    .info-footer {
      display: none;
      @media screen and (min-width: 1280px) {
        display: block;
      }
    }
	}

	.primary-pagination {
		overflow: auto;
	}
}

body > iframe {
	display: none;
}

.dashboard-block {
  & + .dashboard-block {
    margin-top: 40px;
  }
  &_wrap {
    padding-left: 17px;
  }
  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 112%;
    text-transform: capitalize;
    color: #242626;
    border-left: 7px solid #FFE200;
    padding-left: 10px;
    position: relative;
    margin-bottom: 16px;
  }

  .info {
    background: #fff;
    border-radius: 10px;
    padding: 20px 24px;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 129%;
      text-transform: capitalize;
      color: #242626;
      margin: 0;

      strong {
        font-weight: 900;
      }

      & + p {
        margin-top: 10px;
      }
    }

    button {
      background: #F2EDFF;
      border: 1px solid #DCD4F2;
      border-radius: 48px;
      padding: 8px 12px;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #4A3A70;
      margin-top: 14px;
      transition: all ease-in-out .3s;

      &:hover {
        background-color: darken($color: #F2EDFF, $amount: 1.5%);
      }
    }

    .row {
      & > div {
        & + div {
          position: relative;
          &::before {
            height: 124px;
            content: '';
            position: absolute;
            left: -40px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            background-color: #E9D3BF;

            @media screen and (max-width: 1024px) {
              left: -20px;
              height: 80px;
            }

            @media screen and (max-width: 767px) {
              width: 60%;
              height: 1px;
              left: 50%;
              top: -25px;
              transform: translateX(-50%);
            }
          }
        }
      }
    }

    h3 {
      font-size: 14px;
      font-weight: 600;
      padding-left: 0;
      border: none;
      margin-bottom: 24px;
    }
  }


  .assignment-row {
    background: #FFFFFF;
    box-shadow: 0px 4px 34px rgba(223, 229, 236, 0.2);
    border-radius: 8px;
    display: flex;
    align-items: center;
    height: 62px;
    padding: 0 16px;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: stretch;
      height: auto;
      padding: 10px 16px;
      justify-content: stretch;
    }

    & + .assignment-row {
      margin-top: 10px;
    }

    button {
      background: #F2EDFF;
      border: 1px solid #DCD4F2;
      border-radius: 48px;
      padding: 8px 12px;
      font-weight: 400;
      font-size: 12px;
      margin-left: 45px;
      line-height: 15px;
      color: #4A3A70;
      transition: all ease-in-out .3s;

      &:hover {
        background-color: darken($color: #F2EDFF, $amount: 1.5%);
      }
    }

    span {
      background: #F0F9F7;
      margin-left: 7px;
      border-radius: 48px;
      font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #00A689;
      padding: 8px 12px;
    }

    p {
      font-weight: 400;
        font-size: 14px;
        line-height: 129%;
        flex: 1;
        text-transform: capitalize;
        margin: 0;
        color: #242626;
    }
  }

  h5 {
    font-family: 'Kalam', cursive;
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    line-height: 112%;
    text-transform: capitalize;
    color: #4B66F2;
  }
}


// .dashboard {

//   .row {
//     row-gap: 24px;
//   }

//   &_box {
//     border-radius: 10px;
//     padding: 25px 12px;
//     background: #FFEEDF;
//     height: 100%;display: flex;
//     flex-direction: column;
//     min-height: 220px;

//     .position-relative {
//       height: 100%;

//       & > div {
//         width: 60% !important;
//         height: 80%;
//         margin: 0;
//         margin: 0 auto;

//         svg {
//           display: block;
//           width: 100%;
//           height: 100%;
//         }
//       }

//       & > span {
//         margin: 0;
//           height: 80%;
//           top: 0;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//       }
//     }

//     .progress-wrap {
//       display: flex;
//       align-items: center;
//       .progress {
//         flex-grow: 1;
//         background: #FFFFFF;
//         border: none;
//         border-radius: 50px;
//         height: 8px;
//         &-bar {
//           border-radius: 50px;
//           background: #AEAAB8;
//         }
//       }
//       em {
//         padding-left: 8px;
//         font-weight: 400;
//         font-size: 14px;
//         line-height: 17px;
//         color: #111111;
//         font-style: normal;
//       }
//     }

//     strong {
//       font-weight: 700;
//         font-size: 14px;
//         line-height: 129%;
//         display: block;
//         text-align: center;
//         color: #242626;
//     }

//     ul {
//       margin: 0 0 15px;
//       padding: 0;
//       list-style: none;
//       li {
//         font-weight: 600;
//         font-size: 16px;
//         line-height: 150%;
//         color: #111111;
//         display: flex;
//         align-items: center;

//         & + li {
//           margin-top: 5px;
//         }

//         span {
//           display: flex;
//           align-items: center;
//           min-width: 25px;
//           margin-right: 4px;
//         }

//         svg,
//         img {
//           // width: 20px;
//           // height: 20px;
//           color: #00A689;
//         }
//       }
//     }

//     h3 {
//       font-weight: 600;
//       margin: 0;
//       font-size: 18px;
//       line-height: 22px;
//       color: #111111;
//     }

//     p {
//       font-weight: 400;
//       margin-bottom: 25px;
//       font-size: 14px;
//       line-height: 140%;
//       color: #111111;
//     }
//   }

//   &_header {
//     flex-grow: 1;

//     ul {
//       margin: 10px 0 30px;
//       display: flex;
//       align-items: center;
//       flex-wrap: wrap;

//       li {
//         height: 33px;
//         background: #F2EDFF;
//         border: 1px solid #DCD4F2;
//         border-radius: 50px;
//         font-size: 14px;
//         padding: 0 12px;
//         color: #4A3A70;
//         &+li {
//           margin-top: 0;
//           margin-left: 1rem;
//         }
//       }
//     }
//   }
// }

.secondary-header {
  span {
    img {
      height: 40px;
      width: 40px;
      background-color: #f1f1f1;
      border-radius: 50%;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 1279px) {
  .wrapper-content {
    max-width: 100% !important;
  }
}

.test-page--body .left-col {
  .descriptive-type {
    .custom-file {
      .custom-file-label {
        font-size: 14px !important;
      }
    }
    form {
      &> label {
        text-transform: none;
        font-size: 14px !important;
        font-weight: 600;
        color: #000;
        margin-bottom: 0.5rem;
      }
    }
  }

  .supported-docs {
    h6 {
      font-size: 16px !important;
      margin: 0 0 0.5rem;
    }

    .uploaded-files {
      gap: 0.75rem;
      margin-top: 0.5rem;

      &>div {
        padding: 6px 12px 6px 6px;
        height: auto;
        display: flex;
        box-shadow: none;
        align-items: center;
        margin: 0;
        min-width: 1px;

        button {
          padding: 0 !important;
          border: none !important;
          margin-left: 0.5rem;
          svg {
            font-size: 14px !important;
            margin-right: 0 !important;
          }
        }

        svg {
          margin-right: 4px !important;
        }

        a {
          line-height: 1;
          font-size: 16px !important;
        }
      }
    }
  }
}

.instruction-box {
  .instruction-box--body {
    strong {
      font-weight: bold;
    }
    ol {
      border-bottom: 1px solid #eee;
      padding-left: 16px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      padding-bottom: 24px;
      column-gap: 3rem;
      row-gap: 0.5rem;

      li {
        flex-basis: auto;
        &::marker {
          font-weight: bold;
        }
      }
    }
  }
}
